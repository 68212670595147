import React from "react";
import { createUseStyles } from "react-jss";
import { ReasonToChoose } from "../components/organisms/ReasonToChoose";
import { Testimonial } from "../components/organisms/Testimonial";
import { Policy } from "../components/organisms/Policy";
import { Inquiry } from "../components/organisms/Inquiry";
import { FindCarpet } from "../components/organisms/FindCarpet";
import { TopPageContent } from "../components/organisms/TopPageContent";
import { API } from "shared";
import { GetServerSideProps } from "next";
import Head from "next/head";
const useStyles = createUseStyles({
  mainWrapper: {
    marginTop: 50,
    "@media(max-width:768px)": { marginTop: 24 },
    "@media(max-width: 1570px)": {
      padding: "0px 20px",
    },
  },
});
const Homepage = (props: any) => {
  const classes = useStyles();

  return (
    <>
      <Head>
        <meta
          name="description"
          content="Golestan- High Quality Carpets and interior In japan"
        />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        {/* <base
          href="https://my.matterport.com/show/?m=RauHQqdw4Xe"
          target="_blank"
        ></base> */}
      </Head>
      <div className="initialLoader">
        <div className={classes.mainWrapper}>
          <iframe
            width="100%"
            height="480"
            src="https://my.matterport.com/show/?m=RauHQqdw4Xe"
            frameBorder="0"
            allowFullScreen
            allow="xr-spatial-tracking"
          ></iframe>
          <TopPageContent
            intialCarpet={props.carpetFromServer}
            showFilter={true}
            checkMirmehdi={false}
          />
          <Testimonial />
          <ReasonToChoose />
          <Policy />
          <Inquiry />
          <FindCarpet />
        </div>
      </div>
    </>
  );
};
export default Homepage;

export const getServerSideProps: GetServerSideProps = async () => {
  let carpets = [];
  const response = await API.get("/carpet/twelve");

  if (response && response.data) {
    carpets = response.data;
  }

  return {
    props: {
      carpetFromServer: carpets,
    },
  };
};
